import React, { useContext, useEffect, useState } from 'react'
import { FiEdit } from 'react-icons/fi'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { URL_AXIOS } from '../../URL/URL';
import { ThemeContext } from '../../context/ContextHeader';

function ComponentNofiSite({ data }) {

    const [userId, setUserId] = useState(null);
    
    
    useEffect(() => {
        const fetchUserData = async () => {
            try {

                if (data.email) {
                    const response = await axios.get(URL_AXIOS + `get_users_by_email/${data.email_societe}`);
                    if (response.data.status == 200) {
                        setUserId(response.data.users.id);
                      
                        // setNameUser(response.data.users.name)
                        // setBool(false);
                        // console.log(response.data.centresoins)
                    }
                }
            } catch (error) {
                console.error('Error fetching count:', error);

            }
        };
        fetchUserData();
    }, [data.email]);
    return (
        <div className='flex justify-between'>
            <Link to={`/lire-notification/${userId}`}  className='text-black'>
                {data.centressoins}
            </Link>
        </div>
    )
}

export default ComponentNofiSite
