import { Badge } from 'primereact/badge';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { FiEdit } from "react-icons/fi";
import { MdOutlineFilterList } from "react-icons/md";
import { RxAvatar } from "react-icons/rx";
import { IoIosSend } from "react-icons/io";
import { RiAttachment2 } from "react-icons/ri";
import { OverlayPanel } from 'primereact/overlaypanel';

import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ContextMenu } from 'primereact/contextmenu';
import { AiFillFilePdf, AiFillFileZip } from 'react-icons/ai';
import { FaFileAlt, FaFileWord, FaFileExcel } from "react-icons/fa";
import ComponentNofi from './ComponentNofi';
import ComponentNofiSite from './ComponentNofiSite';
import { ThemeContext } from '../../context/ContextHeader';
import { URL_AXIOS, URL_MAIN } from '../../URL/URL';
import Header from '../Header';
import AsideBar from '../AsideBar';
import ReceiveMessageComponent from './ReceiveMessageComponent';
import SendMessageComponent from './SendMessageComponent';
import { Divider } from 'primereact/divider';
import { Button } from 'primereact/button';
function ListeNotificationContent() {
    const usernameconnected = sessionStorage.getItem("nom");
    const societe = sessionStorage.getItem("societe");
    const { id } = useParams();
    const {
        notifications, setNotifications,
        setCountNotif,
        nameUser, setNameUser,
        notificationsAll, setNotificationsAll,
        setNotificationsAllSend, notificationsAllSend,
        textContent, setTextContent,
        noti_id, setNoti_id
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [nomFichier, setNomFichier] = useState("");
    const op = useRef(null);
    const op1 = useRef(null);
    const user_id = sessionStorage.getItem("user_id");
    const site_id = sessionStorage.getItem("site_id");
    const [admins, setAdmins] = useState([]);
    const [membres, setMembres] = useState([]);
    const [toggleOverlayBool, setToggleOverlayBool] = useState(true);
    const [name, setName] = useState('');
    // const [notificationsAll, setNotificationsAll] = useState([]);
    const [notificationsData, setNotificationsData] = useState([]);
    const [display_last_message, setDisplay_last_message] = useState([]);
    const [objet, setObjet] = useState("");
    const [text, setText] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [notificationsCount, setNotificationsCount] = useState([]);
    const [sites, setSites] = useState([]);
    const messageContainerRef = useRef(null);
    const cm = useRef(null);
    const [selectedId, setSelectedId] = useState(0);
    const [boolLoadingMessage, setBoolLoadingMessage] = useState(true);
    const fileInputRef = useRef(null);
    const items = [
        {
            label: 'Modifier',
            icon: 'pi pi-file-edit',


        }
        ,
        {
            separator: true
        },

        {
            label: 'Supprimer',
            icon: 'pi pi-trash',
            command: () => {
                if (selectedId) {

                    delete_notification(selectedId);
                } else {

                }

            }
        }
    ];

    const onRightClick = (event, id) => {
        if (cm.current) {

            setSelectedId(id);
            cm.current.show(event);

        }
    };


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            console.log("Selected file:", file.name);
        }
    };

    const openFileDialog = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Programmatically click the file input
        }
    };

    const liste_membres = async (id_site) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_user_siteBySiteId/${id_site}`);
            if (response.data.status == 200) {
                setMembres(response.data.membres);
                setAdmins(response.data.users_admin);
                setSites(response.data.centre_soin);
            }
        } catch (Error) {

        }
    }
    const delete_notification = async (id_notification) => {
        try {
            const response = await axios.post(URL_AXIOS + `delete_notifications/${id_notification}`);
            if (response.data.status === 200) {

                liste_notificationLoad_recep(id, user_id);
                liste_notification(user_id);

            }
        } catch (Error) {

        }

    }

    const LoadDataUser = async (user_id) => {
        if (user_id) {
            setNameUser("");
            const response = await axios.get(URL_AXIOS + `get_users_by_id/${user_id}`);
            if (response.data.status == 200) {
                setNameUser(response?.data?.users?.name);
            }
        }
    }
    const addNotificat = async () => {
        try {

            if (textContent != "" && id) {
                const data_notifi = {
                    objet: "N/A",
                    contenu: textContent,
                    user_id_sender: user_id,
                    user_id_reception: id,
                    file: selectedFile
                }
                if (noti_id) {
                    const res = await axios.post(URL_AXIOS + `update_notifications/${noti_id}`, data_notifi, {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                        },
                    });

                    if (res.data.status === 200) {

                        setSelectedFile("")
                        setTextContent("");
                        setNoti_id(null);
                        //liste_notificationLoad_recep(user_id, id);
                        liste_notificationLoad_send(user_id, id);
                        // liste_notificationLoad_send(user_id, id);

                        liste_notification(user_id);
                        // lireMessageScroll();


                    } else {
                        Swal.fire({
                            title: "Erreur !",
                            text: "L'enregistrement de la notification a échoué !",
                            icon: "error"
                        })
                    }
                } else {
                    const res = await axios.post(URL_AXIOS + "addNotifications", data_notifi, {
                        headers: {
                            'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' for FormData
                        },
                    });
                    if (res.data.status === 200) {

                        setSelectedFile("")
                        setTextContent("");
                        //liste_notificationLoad_recep(user_id, id);
                        liste_notificationLoad_send(id, user_id);

                        liste_notification(user_id);
                        // lireMessageScroll();


                    } else {
                        Swal.fire({
                            title: "Erreur !",
                            text: "L'enregistrement de la notification a échoué !",
                            icon: "error"
                        })
                    }
                }

            } else {
                Swal.fire({
                    title: "Erreur !",
                    text: "Veuillez renseigner tous les champs obligatoires !",
                    icon: "error"
                })
            }
        } catch (error) {
            // alert("Error saving : " + error.message)
        }

    }



    const new_discussion = (name) => {
        let div_overlay = document.querySelector('.overlayCustomize');

        if (name) {
            setNameUser(name);
            setToggleOverlayBool(false)
            if (div_overlay && !toggleOverlayBool) {
                div_overlay.classList.add("hidden");
                div_overlay.classList.remove("overlayCustomize_anima");
            }
        } else {
            setNameUser("")
        }

    }
    const liste_notification = async (id) => {

        try {
            const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${id}`);
            if (response.data.status === 200) {
                setDisplay_last_message(response.data.display_last_message);
                setCountNotif(response.data.notificationsAllCount);
                setBoolLoadingMessage(false);

            } else {
                setBoolLoadingMessage(false);
            }
        } catch (Error) {
            setBoolLoadingMessage(false);
        }
    }

    const search_message = (e) => {
        try {
            const value = e.target.value.toLowerCase();

            if (value) {
                const filtered = display_last_message.filter((d) =>
                    d.name.toLowerCase().includes(value)
                );
                setDisplay_last_message(filtered)
                console.log(filtered);
            } else {
                liste_notification(user_id)
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const search_membre = (e) => {
        try {
            const value = e.target.value.toLowerCase();

            if (value) {
                // Filter `membres` and `admins` without mutating original data
                const filteredMembres = membres.filter(
                    (d) =>
                        d.nom.toLowerCase().includes(value) ||
                        d.prenom.toLowerCase().includes(value)
                );
                const filteredAdmins = admins.filter((d) =>
                    d.name.toLowerCase().includes(value)
                );

                const filteredSocietes = sites.filter((d) =>
                    d.centressoins.toLowerCase().includes(value)
                );

                // Update state with filtered results
                setMembres(filteredMembres);
                setAdmins(filteredAdmins);
                setSites(filteredSocietes);
            } else {
                // Restore the original lists if the input is empty
                liste_membres(site_id);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };


    const liste_notificationLoad_recep = useCallback(async (id_user_sender, id_user_recep) => {
        if (!id_user_sender || !id_user_recep) {
            console.warn("Missing sender or receiver ID");
            return;
        }

        try {
            const response = await axios.get(`${URL_AXIOS}laodMessageNotification/${id_user_sender}/${id_user_recep}`);
            if (response.data.status === 200) {
                setNotificationsAll(response.data.display_last_message);
            } else {
                console.warn("Unexpected status code:", response.data.status);
            }
        } catch (error) {
            console.error("Error fetching received notifications:", error);
        }
    }, [setNotificationsAll]);

    const liste_notificationLoad_send = useCallback(async (id_user_sender, id_user_recep) => {
        if (!id_user_sender || !id_user_recep) {
            console.warn("Missing sender or receiver ID");
            return;
        }

        try {
            const response = await axios.get(`${URL_AXIOS}laodMessageNotification/${id_user_sender}/${id_user_recep}`);
            if (response.data.status === 200) {
                setNotificationsAll(response.data.display_last_message);
            } else {
                console.warn("Unexpected status code:", response.data.status);
            }
        } catch (error) {
            console.error("Error fetching sent notifications:", error);
        }
    }, [setNotificationsAll]);


    const updateStatus = async (id, user_id2) => {
        try {
            const response = await axios.put(URL_AXIOS + `updateStatus/${id}/${user_id2}`);
            if (response.data.status === 200) {
                liste_notification(user_id);

            }
        } catch (Error) {
            console.log("Erreur");
        }
    }

    const lireMessage = (name) => {
        setNameUser(name)
        updateStatus(user_id, id);
    }




    const lireMessageScroll = useCallback(() => {
        if (!messageContainerRef.current) return;

        const messages = messageContainerRef.current.children;
        if (messages.length === 0) return;

        const lastMessage = messages[messages.length - 1];
        if (lastMessage) {
            lastMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, []);


    const renderFilePreview = () => {
        if (!selectedFile) return null;

        const fileType = selectedFile.type;
        const fileExtension = name.split('.').pop().toLowerCase();
        if (fileType.startsWith("image/")) {
            return (
                <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Preview"
                    className="img-preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                />
            );
        } else if (fileType === "application/pdf") {
            return (
                <div className="file-icon">
                    <AiFillFilePdf size={50} color="red" />
                    <p>{selectedFile.name}</p>
                </div>
            );
        } else if (
            fileType === "application/x-rar-compressed" ||
            fileType === "application/zip"
        ) {
            return (
                <div className="file-icon">
                    <AiFillFileZip size={50} color="blue" />
                    <p>{selectedFile.name}</p>
                </div>
            );
        } else if (['doc', 'docx'].includes(fileExtension)) {
            return (
                <div className="file-icon cursor-pointer">
                    <FaFileWord size={50} color="blue" />
                </div>
            );
        } else if (['xls', 'xlsx'].includes(fileExtension)) {
            return (
                <div className="file-icon cursor-pointer">
                    <FaFileExcel size={50} color="green" />
                </div>
            );
        } else {
            return (
                <div className="file-icon">
                    <FaFileAlt size={50} color="gray" />
                    <p>{selectedFile.name}</p>
                </div>
            );
        }
    };

    // const handleDownload = (e, val) => {
    //     e.preventDefault();
    //     //const getURL = getURLFolder;
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = val;
    //     downloadLink.download = val;
    //     downloadLink.target = "_blank";
    //     downloadLink.click();
    // };
    // const renderFilePreviewLoad = (name) => {
    //     if (!name) return null;
    //     const filename = URL_MAIN + "notifications_files/" + name;
    //     // Get the file extension
    //     const fileExtension = name.split('.').pop().toLowerCase();

    //     if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp'].includes(fileExtension)) {
    //         return (
    //             <img
    //                 src={`${URL_MAIN + "notifications_files"}/${name}`} // Adjust the path based on your server setup
    //                 alt="Preview"
    //                 className="img-preview"
    //                 style={{ maxWidth: "100px", maxHeight: "100px" }}
    //             />
    //         );
    //     } else if (fileExtension === 'pdf') {
    //         return (
    //             <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
    //                 <AiFillFilePdf size={50} color="red" />

    //             </div>
    //         );
    //     } else if (['rar', 'zip', '7z'].includes(fileExtension)) {
    //         return (
    //             <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
    //                 <AiFillFileZip size={50} color="blue" />

    //             </div>
    //         );
    //     } else if (['doc', 'docx'].includes(fileExtension)) {
    //         return (
    //             <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
    //                 <FaFileWord size={50} color="blue" />
    //             </div>
    //         );
    //     } else if (['xls', 'xlsx'].includes(fileExtension)) {
    //         return (
    //             <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
    //                 <FaFileExcel size={50} color="green" />
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div onClick={(e) => handleDownload(e, filename)} className="file-icon cursor-pointer">
    //                 <FaFileAlt size={50} color="gray" />

    //             </div>
    //         );
    //     }
    // };



    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [notificationsAll, notificationsAllSend]);



    // useEffect(() => {
    //     if (id) {
    //         // liste_notificationLoad(id);
    //         lireMessageScroll();
    //         // LoadDataUser(id)
    //     }
    // }, [id, lireMessageScroll]);

    useEffect(() => {
        if (id) {
            updateStatus(user_id, id);
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            LoadDataUser(id)
        }
    }, [id]);

    useEffect(() => {
        if (user_id) {
            liste_notification(user_id);
            liste_membres(parseInt(user_id));
           
        }
    }, []);

    // useEffect(() => {

    //     const loadNotifications = async (id, user_id) => {
    //         if (id && user_id) {
    //             await liste_notificationLoad_recep(user_id, id); // Wait for data to load
    //              await liste_notificationLoad_send(id,user_id); // Wait for data to load
    //             lireMessageScroll(); // Scroll to the last message
    //         }
    //     };
    //     loadNotifications(id, user_id);
    // }, [id, user_id, liste_notificationLoad_recep,liste_notificationLoad_send, lireMessageScroll]);

    useEffect(() => {
        // fetchMembres();
        if (usernameconnected === null || usernameconnected === "") {
            navigate('/')
        }
    }, []);


    useEffect(() => {
        const handleMouseDown = (event) => {
            const div_overlay = document.querySelector('.overlayCustomize');
            if (div_overlay && !div_overlay.contains(event.target)) {
                div_overlay.classList.add("hidden");
            }
        };

        document.addEventListener("mousedown", handleMouseDown);

        return () => {
            document.removeEventListener("mousedown", handleMouseDown);
        };
    }, []);
    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            <section id="main-content">
                <section className="wrapper">
                    <div className='flex w-full relative  bottom-0 mt-[20px]'>
                        <div className='w-[30%] bg-[#eaeaea] h-[500px] relative overflow-hidden'>
                            <div className='flex p-[10px] justify-between bg-[#fff] relative'>
                                <div className='w-[100%] mt-[10px] relative flex justify-between'>
                                    <p className='text-[#4ecdc4] font-bold size-[25px]'>Discussions</p>
                                    <div className='flex justify-between'>
                                       
                                        {/* <FiEdit className='text-[#4ecdc4] size-[20px] cursor-pointer' onClick={(e) => op.current.toggle(e)} /> */}
                                        <Button  icon="pi pi-external-link" className='h-10' onClick={(e) => op.current.toggle(e)} />
                                        <OverlayPanel ref={op}>
                                            <div className='w-[350px] h-auto p-[10px]'>
                                                <input type='text' onChange={search_membre} className='form-control' placeholder='Rechercher un nom' />
                                                <div className='mt-[5px] h-[350px] relative overflow-x-hidden overflow-y-auto'>
                                                    {admins.map((m, index_admin) => (
                                                        <Link to={`/lire-notification/${m.id}`} className='flex cursor-pointer mt-[5px] text-black hover:bg-gray-100' key={index_admin} onClick={() => new_discussion(m.name)}>
                                                            <RxAvatar className='w-[50px] h-[50px]' />
                                                            <h5 className='ml-[10px] mt-[15px]'>{m.name}</h5>
                                                        </Link>
                                                    ))}
                                                    {membres.map((m, index) => (
                                                        <Link to={`/lire-notification/${m.id}`} className='flex cursor-pointer mt-[5px] text-black hover:bg-gray-100' key={index} onClick={() => new_discussion(m.name)}>
                                                            <RxAvatar className='w-[50px] h-[50px]' />
                                                            <h5 className='ml-[10px] mt-[15px]'>{m.name}</h5>
                                                        </Link>
                                                    ))}
                                                    {sites.map((s, index) => (
                                                        <Link to={`/lire-notification/${s.id}`} className='flex cursor-pointer mt-[10px] text-black hover:bg-gray-100' key={index} onClick={() => new_discussion(s.name)}>
                                                            <RxAvatar className='w-[50px] h-[50px]' />
                                                            <h5 className='ml-[10px] mt-[15px]'>{<ComponentNofiSite data={s} />}</h5>
                                                        </Link>
                                                    ))}
                                                </div>
                                            </div>
                                        </OverlayPanel>
                                    </div>
                                </div>
                            </div>

                            <div className='p-[10px]'>
                                <IconField iconPosition="left">
                                    <InputIcon className="pi pi-search"></InputIcon>
                                    <InputText onChange={(e) => search_message(e)} placeholder="Rechercher ou démarrer une discussion" className='w-full px-[30px] h-[30px]' />
                                </IconField>
                            </div>

                            <div className='h-[80%] overflow-x-hidden overflow-y-auto p-[10px] relative z-10'>
                                {!boolLoadingMessage && display_last_message.length == 0 && (
                                    <div>
                                        <p className='text-red-600 font-bold'>Aucun message</p>
                                    </div>
                                )}
                                {boolLoadingMessage ? (
                                    <p>Chargement en cours...</p>
                                ) : (
                                    display_last_message.map((data, index) => (
                                        <Link
                                            to={`/lire-notification/${data.DataUsers.id_user_recep == user_id ? data.DataUsers.user_id_sender : data.DataUsers.id_user_recep}`}
                                            className="flex z-0 w-full cursor-pointer hover:bg-gray-100 hover:rounded-full focus:bg-gray-100"
                                            key={index}
                                        >
                                            <div className="flex w-full">
                                                <div className="w-[40px] h-[40px] mt-[13px] bg-white rounded-full">
                                                    <RxAvatar className='w-[40px] h-[40px] text-black' />
                                                </div>
                                                <div className="flex flex-col mt-[4px] ml-2 relative w-full">
                                                    <h5 className="text-[#4ecdc4] text-lg font-bold">{data.name}</h5>
                                                    <div className="text-[#4ecdc4] flex justify-between mt-[-10px]">
                                                        <p className="text-black text-lg">
                                                            {data.DataUsers.contenu.length > 20
                                                                ? `${data.DataUsers.contenu.substring(0, 20)}...`
                                                                : data.DataUsers.contenu}
                                                        </p>
                                                        {data.unread_count > 0 && <Badge value={data.unread_count} severity="success"></Badge>}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                )}
                            </div>
                        </div>
                        <div className='w-[1%]'>

                        </div>
                        <div className='w-[69%] bg-[#eaeaea] relative'>
                            <div className='p-[10px] flex bg-[#fff] '>
                                <div className='w-[40px] h-[40px] bg-white rounded-full'>
                                    <img className='rounded-full w-full h-full' src='https://www.shutterstock.com/image-vector/portrait-beautiful-latin-american-woman-600nw-2071168457.jpg' />
                                </div>
                                <div className='p-[4px] ml-[50px] mt-[-3px] absolute w-full'>
                                    <h5 className='text-[#4ecdc4]  font-bold'>{nameUser}</h5>
                                </div>

                            </div>
                            <div ref={messageContainerRef} className='w-full flex flex-col h-[380px] overflow-x-hidden overflow-y-auto p-[10px]'>

                                <ReceiveMessageComponent user_id_sender={id} user_id_reception={user_id} />

                            </div>
                            {/* <div ref={messageContainerRef} className='w-full flex flex-col h-[380px] overflow-x-hidden overflow-y-auto p-[10px]'>
                                {(notificationsAll.length == 0 && notificationsAllSend.length == 0) && <div className='flex justify-center items-center'>
                                    Veuillez commencer une nouvelle discussion...
                                </div>}

                                <div className="mt-[10px] flex flex-col">
                                    <ReceiveMessageComponent user_id_sender={id} user_id_reception={user_id} />
                                    <SendMessageComponent user_id_sender={user_id} user_id_reception={id} />
                                </div>

                                {selectedFile && (
                                    <div className="file-preview mt-2">
                                        {renderFilePreview()}
                                    </div>
                                )}
                            </div> */}
                            <div className='flex mt-[10px]'>
                                <div className='flex w-[80%] flex-col'>

                                    {/* {noti_id && <div className='absolute z-10 top-0 left-0 opacity-15 h-[89%] bg-[#000] w-[100%] '>

                                        <div className='absolute left-1 z-15 bg-[#4ecdc4] h-auto w-[60%] rounded top-[89%]'>
                                            {textContent}
                                        </div>
                                    </div>} */}
                                    <textarea onk type='text' value={textContent} onChange={(e) => setTextContent(e.target.value)} placeholder='Taper message' className=' form-control mb-auto overflow-hidden'></textarea>
                                </div>
                                <div className='flex items-center ml-[20px]'>
                                    <input
                                        type="file"
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                        className='form-control hidden'
                                    />
                                    <button type='button' onClick={addNotificat} className='btn btn-info size-[50px]'>
                                        <IoIosSend className='size-[30px]' />
                                    </button>
                                    <button
                                        type='button'
                                        onClick={openFileDialog}
                                        className='btn btn-primary size-[50px] ml-[10px]'
                                    >
                                        <RiAttachment2 className='size-[30px]' />
                                    </button>

                                </div>
                            </div>
                        </div>
                    </div >
                </section>
            </section>
        </div>


    )
}

export default ListeNotificationContent
