
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CardResultat from './components/centers/CardResultat';
import CompteExpertise from './components/centers/CompteExpertise';
import Notifications from './components/centers/Notifications';
import Statistiques from './components/centers/Statistiques';
import ModelCompteRendu from './components/centers/ModelCompteRendu';
import Archives from './components/centers/Archives';
import DetailDossiersEnvoye from './components/centers/DetailDossiersEnvoye';
import { ThemeProvider } from './context/ContextHeader';
import Corbeille from './components/centers/Corbeille';
import Apercu from './components/centers/Apercu';
import AddNotification from './components/centers/AddNotification';
import LectureNotification from './components/centers/LectureNotification';
import EditionNotification from './components/centers/EditionNotification';
import Login from './components/Login';
import EditorM from './components/EditorM';
import ApercuAvis from './components/centers/ApercuAvis';
import { PrimeReactProvider } from 'primereact/api';
import DetailFoldersReceive from './components/centers/DetailFoldersReceive';
import AddMcr from './components/centers/AddMcr';
import EditionMcr from './components/centers/EditionMcr';
import DetailDossiersAvis from './components/centers/DetailDossiersAvis';
import Loading from './components/centers/Loading';
import Profil from './components/centers/profil';
import MembreInfoAdd from './components/centers/MembreInfoAdd';
import UserLoginCreate from './components/centers/UserLoginCreate';
import ListeNotificationContent from './components/centers/ListeNotificationContent';
function App() {

  return (
    <PrimeReactProvider>
      <Router>
        <ThemeProvider>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route path="/resultat" element={<CardResultat />} />
            <Route path="/editor" element={<EditorM />} />
            <Route path="/resultat/:id" element={<CardResultat />} />
            <Route path="/compte_expertise" element={<CompteExpertise />} />
            <Route path="/notifications" element={<ListeNotificationContent />} />
            <Route path="/lire-notification/:id" element={<ListeNotificationContent />} />
            <Route path="/statistiques" element={<Statistiques />} />
            <Route path="/model_compte_rendu" element={<ModelCompteRendu />} />
            <Route path="/archives" element={<Archives />} />
            <Route path="/corbeille" element={<Corbeille />} />
            <Route path="/apercu/:id" element={<Apercu />} />
            <Route path="/apercu_avis/:id/" element={<ApercuAvis />} />
            <Route path="/detail_dossier_patient/:id" element={<DetailDossiersEnvoye />} />
            <Route path="/detail_dossier_patient_avis/:avis_id" element={<DetailDossiersAvis />} />
            <Route path="/addNotifications" element={<AddNotification />} />
            <Route path="/lireNotification" element={<LectureNotification />} />
            <Route path="/editionNotification/:id" element={<EditionNotification />} />
            <Route path="/member_detail_folder/:id" element={<DetailFoldersReceive />} />
            <Route path="/addCompteRendu" element={<AddMcr />} />
            <Route path="/editionMcr/:id" element={<EditionMcr />} />
            <Route path="/loading" element={<Loading />} />
            <Route path="/profil" element={<Profil />} />
            <Route path="/add-infos-membres" element={<MembreInfoAdd />} />
            <Route path="/create-compte" element={<UserLoginCreate />} />
          </Routes>
        </ThemeProvider>
      </Router>
    </PrimeReactProvider>

  );
}

export default App;
