import { useEffect, useState } from "react";
import { createContext } from "react";
import { URL_AXIOS, URL_SIGNATURE } from "../URL/URL";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useIdleTimer from "../components/centers/useIdleTimer";
export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const user_id = sessionStorage.getItem("user_id");
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const usernameconnected = sessionStorage.getItem("nom");
    const navigate = useNavigate();
    // sessionStorage.getItem("membre_id");
    const [text, setText] = useState("");
    const [nbFoldersSend, setNbFoldersSend] = useState(0);
    const [datedebut, setDatedebut] = useState('');
    const [mcr, setMcr] = useState([]);
    const [datefin, setDateFin] = useState('');
    const [dossiers, setDossiers] = useState([]);
    const [dossiersEn, setDossiersEn] = useState([]);
    const [disable, setDisable] = useState(false);
    const [disableOption, setDisableOption] = useState(false);
    const [disableAvis, setDisableAvis] = useState(false);
    const [disableRefresh, setDisableRefresh] = useState(false);
    const [items, setItems] = useState([]);
    const [nbFoldersRemove, setNbFoldersRemove] = useState(0);
    const [CompteExpertiseData, setCompteExpertiseData] = useState([]);
    const [dataAvisSend, setDataAvisSend] = useState([]);
    const [dataAvisReceive, setDataAvisReceive] = useState([]);
    const [medecinID, setMedecinID] = useState(membre_id);
    const [nbFoldersArchive, setNbFoldersArchive] = useState(0);
    const [nbFolderAvisSend, setNbFolderAvisSend] = useState(0);
    const [nbFolderAvisReceive, setNbFolderAvisReceive] = useState(0);
    const [notifi, setNotifi] = useState([]);
    const [notifiRecep, setNotifiRecep] = useState([]);
    const [notifiLimit, setNotifiLimit] = useState([]);
    const [objet, setObjet] = useState("");
    const [contenu, setContenu] = useState("");
    const [name_recep, setName_recep] = useState("");
    const [societe_recep, setSociete_recep] = useState("");
    const [fichier_notification, setFichier_notification] = useState("");
    const [created_at, setCreated_at] = useState();
    const [name_sender, setName_sender] = useState("");
    const [societe_sender, setSociete_sender] = useState("");
    const [getURLFolder, setGetURLFolder] = useState('');
    const [dataMembre, setDataMembre] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [userID, setUserID] = useState(11);
    const [contentMrc, setContentMrc] = useState("");
    const [statutView, setStatutView] = useState('');
    const [dossiersArchive, setDossiersArchive] = useState([]);
    const [dossiersSousArchive, setDossiersSousArchive] = useState([]);
    const [dossiersCorbeille, setDossiersCorbeille] = useState([]);
    const [dossiersSousCorbeille, setDossiersSousCorbeille] = useState([]);
    const [dataExamenStatistique, setDataExamenStatistique] = useState([]);
    const [periodeContext, setPeriodeContext] = useState("");
    const [examen_total, setExamen_total] = useState(0);
    const [visibleModelSignature, setVisibleModelSignature] = useState(false);
    const [boolResultat, setBoolResult] = useState(true);
    const [boolCompte_expertise, setBoolCompte_expertise] = useState(false);
    const [boolNotifications, setBoolNotifications] = useState(false);
    const [boolStatistique, setBoolStatistique] = useState(false);
    const [boolMcr, setBoolMcr] = useState(false);
    const [boolArchives, setBoolArchives] = useState(false);
    const [boolCorbeille, setBoolCorbeille] = useState(false);
    const [visibleProfile, setVisibleProfile] = useState(false);
    const [titles, setTitles] = useState({});
    const [toggleAvis, setToggleAvis] = useState(0);
    const [avatar, setAvatar] = useState("");
    const [statusProgress,setStatusProgress]=useState(false);
    const [display_last_message, setDisplay_last_message] = useState([]);
    const [boolLoadingMessage, setBoolLoadingMessage] = useState(true);
    const [notificationsAll, setNotificationsAll] = useState([]);
    const [notificationsAllSend, setNotificationsAllSend] = useState([]);
    const [textContent,setTextContent]=useState("");
    const [noti_id,setNoti_id]=useState(null);
    const [nameUser, setNameUser] = useState('');
    useIdleTimer(30 * 60 * 1000); // 15 minutes d'inactivité
  
    const liste_membres_by_id = async (id) => {
        try {
            if (id) {
                const res = await axios.get(URL_AXIOS + `get_membre_by_id/${id}`);
                if (res.data.status == 200) {
                    const path_photo = URL_SIGNATURE + res.data.membres.cheminfichiers + "/" + res.data.membres.image;
                    (res.data.membres.image !== null && res.data.membres.image !== "") ? setAvatar(path_photo) : setAvatar("../../logo.png")

                } else {

                }
            }

        } catch (Error) {

        }

    }
    const list_mcr = async (id_member) => {
        try {
            if (id_member) {

                const response = await axios.get(URL_AXIOS + `list_mcr_allByMember/${id_member}`);
                if (response.data.status === 200) {
                    setMcr(response.data.mcr);

                }
            }

        } catch (Error) {

        }
    }

    const BulleCorbeille = async (id) => {
        try {
            if (id) {
                const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
                if (response.data.status === 200) {
                    // setDossiersEn(response.data.dossier_send);
                    //setDossiers(response.data.data_dossier_sub);
                    setNbFoldersRemove(response.data.data_dossier_num.length)
                }
            }

        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const BulleResultat = async (id) => {
        try {
            if (id) {
                const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
                if (response.data.status === 200) {
                    setNbFoldersSend(response.data.data_dossier_num.length)
                }
            }

        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }

    const BulleArchive = async (id) => {
        try {
            if (id) {
                const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
                if (response.data.status === 200) {
                    setDossiersArchive(response.data.dossier_send);
                }
            }

        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }


    const liste_dossiers_by_siteEnteteAccordion = async (id) => {
        try {
            if (id) {
                const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
                if (response.data.status === 200) {
                    setDossiersEn(response.data.dossier_send);
                }
            }

        } catch (error) {
            // alert("Erreur " + error.message);
        }
    }
    const liste_dossiers_by_siteEnteteAccordionArchive = async (id) => {
        try {
            if (id) {
                const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_archive/${id}`);
                if (response.data.status == 200) {
                    setDossiersArchive(response.data.dossier_send);
                }
            }

        } catch (error) {

        }
    }
    const liste_dossiers_by_siteEnteteAccordionCorbeille = async (id) => {
        try {
            if (id) {
                const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete_retire/${id}`);
                if (response.data.status === 200) {
                    setDossiersCorbeille(response.data.dossier_send);
                }
            }

        } catch (error) {
            //alert("Erreur " + error.message);
        }
    }
    const BulleAvisSend = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_envoye/${val}`);
                if (response.data.status === 200) {
                    setNbFolderAvisSend(response.data.data_avis_send.length);

                } else {

                }
            }

        } catch (error) {

        }
    }

    const BulleAvisReceive = async (val) => {
        try {
            if (val !== null) {
                const response = await axios.get(URL_AXIOS + `liste_avis_sollicite/${val}`);
                if (response.data.status === 200) {
                    setNbFolderAvisReceive(response.data.data_avis_sollicite.length);


                } else {

                }
            }

        } catch (error) {

        }
    }


    const liste_notificationRecep = async (user_id) => {
        try {
            if (user_id) {
                const response = await axios.get(URL_AXIOS + `CountNumberOFNotification/${user_id}`);
                if (response.data.status === 200) {
                    setNotifi(response.data.notificationSend);
                    setNotifiLimit(response.data.notificationsLimit);
                }
            }

        } catch (Error) {

        }
    }

    const liste_notificationByUsers = async (user_id) => {
        try {
            if (user_id) {
                const response = await axios.get(URL_AXIOS + `ListeNotificationReception/${user_id}`);
                if (response.data.status === 200) {
                    setNotifiRecep(response.data.notifications);
                }
            }

        } catch (Error) {

        }
    }
    const liste_notificationSendersByUsers = async (user_id) => {
        try {
            if (user_id) {
                const response = await axios.get(URL_AXIOS + `liste_notificationsByMembreId/${user_id}`);
                if (response.data.status === 200) {
                    setNotifications(response.data.notifications);
                    setNotifi(response.data.notifications);
                    setDataMembre(response.data);
                }
            }

        } catch (error) {
            // alert("Erreur " + error.message)
        }
    }
    // useEffect(()=>{
    //     if (usernameconnected === null || usernameconnected === "" || usernameconnected==undefined) {
    //         navigate('/')
    //     }
    // },[usernameconnected])
    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            BulleCorbeille(membre_id);
        }

    }, [membre_id]);
    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            list_mcr(membre_id);
        }

    }, [membre_id]);

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            BulleResultat(membre_id);
        }

    }, [membre_id]);

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            BulleArchive(membre_id);
        }

    }, [membre_id]);

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            liste_membres_by_id(membre_id)
        }

    }, [membre_id]);

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            BulleAvisReceive(membre_id);
        }

    }, [membre_id])

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            BulleAvisSend(membre_id);
        }

    }, [membre_id])

    useEffect(() => {
        if (user_id !== "" || user_id !== null) {
            liste_notificationRecep(user_id);
        }

    }, [user_id, notifiLimit])


    useEffect(() => {
        if (user_id !== "" || user_id !== null) {
            liste_notificationByUsers(user_id);
        }

    }, [user_id])

    useEffect(() => {
        if (user_id !== "" || user_id !== null) {
            liste_notificationSendersByUsers(user_id);
        }

    }, [user_id])

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            liste_dossiers_by_siteEnteteAccordion(membre_id);
        }

    }, [membre_id])



    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            liste_dossiers_by_siteEnteteAccordionArchive(membre_id);
        }

    }, [membre_id])

    useEffect(() => {
        if (membre_id !== "" || membre_id !== null) {
            liste_dossiers_by_siteEnteteAccordionCorbeille(membre_id);
        }

    }, [membre_id])
    return (
        <ThemeContext.Provider value={{
            text, setText,
            datedebut, setDatedebut,
            datefin, setDateFin,
            dossiers, setDossiers,
            dossiersEn, setDossiersEn,
            items, setItems,
            nbFoldersSend, setNbFoldersSend,
            nbFoldersRemove, setNbFoldersRemove,
            disable, setDisable,
            disableAvis, setDisableAvis,
            CompteExpertiseData, setCompteExpertiseData,
            dataAvisSend, setDataAvisSend,
            dataAvisReceive, setDataAvisReceive,
            medecinID, setMedecinID,
            nbFoldersArchive, setNbFoldersArchive,
            nbFolderAvisSend, setNbFolderAvisSend,
            nbFolderAvisReceive, setNbFolderAvisReceive,
            notifi, setNotifi,
            notifiLimit, setNotifiLimit,
            objet, setObjet,
            contenu, setContenu,
            name_recep, setName_recep,
            societe_recep, setSociete_recep,
            fichier_notification, setFichier_notification,
            created_at, setCreated_at,
            name_sender, setName_sender,
            societe_sender, setSociete_sender,
            getURLFolder, setGetURLFolder,
            dataMembre, setDataMembre,
            notifications, setNotifications,
            notifiRecep, setNotifiRecep,
            userID, setUserID,
            disableRefresh, setDisableRefresh,
            contentMrc, setContentMrc,
            statutView, setStatutView,
            dossiersArchive, setDossiersArchive,
            dossiersSousArchive, setDossiersSousArchive,
            dossiersCorbeille, setDossiersCorbeille,
            dossiersSousCorbeille, setDossiersSousCorbeille,
            dataExamenStatistique, setDataExamenStatistique,
            periodeContext, setPeriodeContext,
            examen_total, setExamen_total,
            visibleModelSignature, setVisibleModelSignature,
            boolResultat, setBoolResult,
            boolCompte_expertise, setBoolCompte_expertise,
            boolNotifications, setBoolNotifications,
            boolStatistique, setBoolStatistique,
            boolMcr, setBoolMcr,
            boolArchives, setBoolArchives,
            boolCorbeille, setBoolCorbeille,
            mcr, setMcr,
            visibleProfile, setVisibleProfile,
            titles, setTitles,
            toggleAvis, setToggleAvis,
            disableOption, setDisableOption,
            avatar,
            statusProgress,setStatusProgress,
            nameUser, setNameUser,
            display_last_message, setDisplay_last_message,
            boolLoadingMessage, setBoolLoadingMessage,
            notificationsAll, setNotificationsAll,
            notificationsAllSend, setNotificationsAllSend,
            textContent,setTextContent,
            noti_id,setNoti_id

        }}>
            {children}
        </ThemeContext.Provider>
    )
}