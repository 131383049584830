import { baseApi } from "../../baseApi";

export const dossiersApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getDetailDossier: builder.query({
            query: (id_dossier) => `liste_interpretation/${parseInt(id_dossier)}`,
            transformResponse: (response) => ({
                observation: response.dossierInter.observation,
                inter_id: response.dossierInter.inter_id,
                img_obs_1: response.dossierInter.img_obs_1,
                img_obs_2: response.dossierInter.img_obs_2,
                img_obs_3: response.dossierInter.img_obs_3,
            }),
        }),

        getDetailDossierDonnee: builder.query({
            query: (id_dossier) => `liste_dossiers_by_dossiersId/${parseInt(id_dossier)}`,
            transformResponse: (response) => response.dossier_send,
        }),
        UpdateInterpretationImage: builder.mutation({
            query: (formData) => {
                return {
                    url: 'updateImages',
                    method: 'POST',
                    body: formData,
                    headers:{
                        'Content-Type': 'multipart/form-data',
                    },
                };
            }
        }),

    })
});

export const { useGetDetailDossierQuery, useGetDetailDossierDonneeQuery,useUpdateInterpretationImageMutation } = dossiersApi;