import React from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
function LoadingSpinner() {
    return (
        <div style={{ 
            position: "absolute", 
            height: "100%", 
            width: "100%", 
            display: "flex", 
            justifyContent: "center", 
            alignItems: "center", 
            alignContent: "center", 
            flexDirection: "column" 
        }}>
               <ProgressSpinner style={{ width: '80px', height: '80px',zIndex:1000 }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" aria-label="Loading"/>
        </div>
    )
}

export default LoadingSpinner
