import React from 'react'
import { BiBorderRadius } from 'react-icons/bi'

function ProgressAction() {
    return (
        <div style={{
            position: "absolute",
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
            flexDirection: "row"
        }}>
            <div style={{
                display: "flex",
                width: "180px",
                height: "110px",
                backgroundColor: "#4ECDC4",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "colum",
                padding:"15px",
                borderRadius:5
               
            }}>
                <div className="spinner"></div>
                <p style={{marginTop:"90px",color:"white",fontWeight:"bold"}}>Veuillez patienter ...</p>
              
            </div>
        </div>
        
        
    )
}

export default ProgressAction
