import { configureStore } from '@reduxjs/toolkit'
import { baseApi } from './baseApi';
import { dossiersApi } from './features/dossiers/dossiersApi';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]:baseApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
});
