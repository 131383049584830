import React, { useContext, useEffect, useState } from 'react';
import Header from '../Header';
import AsideBar from '../AsideBar';
import Footer from '../Footer';
import { Card } from 'primereact/card';
import axios from 'axios';
import { URL_AXIOS, URL_FOLDER_API } from '../../URL/URL';
import { Link, useNavigate } from 'react-router-dom';
import { Checkbox } from "primereact/checkbox";
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Badge } from 'primereact/badge'
import { ThemeContext } from '../../context/ContextHeader';
import { useRef } from 'react';
import { BiEdit } from "react-icons/bi";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Session from 'react-session-api';
import Loading from './Loading';
import ProgressAction from './ProgressAction';
function CardResultat() {
    const {
        text, setText,
        datedebut, dossiersEn,
        setDossiersEn, dossiers,
        setDossiers, items, setItems,
        nbFoldersSend, setNbFoldersSend,
        disable, setDisable,
        disableAvis, setDisableAvis,
        medecinID, setMedecinID,
        contentMrc, setContentMrc,
        statutView, setStatutView,
        titles, setTitles,
        disableOption, setDisableOption,
        statusProgress
    } = useContext(ThemeContext);
    const navigate = useNavigate();
    const usernameconnected = sessionStorage.getItem("nom");
    const membre_id = parseInt(sessionStorage.getItem("membre_id"));
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 25;
    const firstIndex = (currentPage - 1) * recordsPerPage;
    const lastIndex = firstIndex + recordsPerPage;
    const records = dossiersEn.slice(firstIndex, lastIndex);
    const npage = Math.ceil(dossiersEn.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);
    const [checked, setChecked] = useState(false);
    const [membreRecep, setMembreRecep] = useState([])

    const prevPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }

    const nextPage = () => {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    const changeCPage = (id) => {
        setCurrentPage(id)
    }
    const [ingredients, setIngredients] = useState([]);
    const [sites, setSites] = useState([]);
    const [statusResultat, setStatusResultat] = useState(false);
    const [isChecked, setIsChecked] = useState([]);
    const useRefInput = useRef(null);
    const [LoadingState, setLoadingState] = useState(false);




    const onIngredientsChange = (e, statut,exp) => {
        let _ingredients = [...ingredients];
        if (e.checked)
            _ingredients.push(e.value);

        else
            _ingredients.splice(_ingredients.indexOf(e.value), 1);

        setIngredients(_ingredients);
        setItems(_ingredients);
        if (_ingredients.length > 0) {

            if ((statut === "1" || statut === "2") && exp=='N' ) {
                setDisableOption(false)
                setDisableAvis(false);
            } else if (statut === "0" && exp=='N' ) {
                setDisableOption(false);
                setDisableAvis(false)
            }else{
                setDisableOption(false);
                setDisableAvis(false)
            }
        } else {
            setDisableOption(true)
            setDisableAvis(true)
        }
        e.stopPropagation();
    }


    const handleApercu = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';
        link.href = `/apercu/${value}`;
        link.target = "_blank";
        link.click();
        e.stopPropagation();
    };

    const handleApercuAvis = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';
        link.href = `/apercu_avis/${value}`;
        link.target = "_blank";
        link.click();
        e.stopPropagation();
    };

    const headerAccordion = (societe, id) => {
        return (
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col col-md-4'>
                        <h6>{societe}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6>
                        <p>{id}</p>
                    </div>

                </div>

            </div>
        )
    }
    const liste_dossiers_by_siteEnteteAccordion = async (id) => {
        try {
            const response = await axios.get(URL_AXIOS + `liste_dossiers_by_site_entete/${id}`);
            if (response.data.status === 200) {
                setDossiersEn(response.data.dossier_send);
                setLoadingState(true);
                //setDossiers(response.data.data_dossier_sub);
                //setNbFoldersSend(response.data.data_dossier_num.length)
            } else {
                alert("Erreur " + membre_id)
            }
        } catch (error) {
            alert("Erreur " + error.message);
        }
    }
    const liste_site = async () => {
        try {
            const response = await axios.get(URL_AXIOS + 'sites');
            if (response.data.status === 200) {
                setSites(response.data.sites);

            }
        } catch (Error) {

        }

    }
    const Title = (societe, id) => {
        return (
            <div style={{ display: 'flex', alignItems: 'start' }}>
                <h5 style={{ color: '#18d26e' }}><i className='fa fa-user '>&nbsp;</i>{societe}</h5>

            </div>
        )
    }
    const handleClick = (event, value) => {
        event.preventDefault();

        return value;
    };

    const handleField = (e) => {


        alert("Ils sont en train d'écrire");

    };
    const handleDownload = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        link.download = 'Example-PDF-File';
        link.href = value;
        link.click();
    };

    const handleOpen = (e, value) => {
        e.preventDefault();
        const link = document.createElement('a');
        //link.download = 'Example-PDF-File';

        link.href = value;
        link.target = "_blank";

        link.click();
    };

    const liste_dossiers_interprete = async (id_dossier) => {
        try {

            const response = await axios.get(URL_AXIOS + `liste_interpretation/${id_dossier}`);
            if (response.status === 200) {
                const observation = response.data.observation;
                setContentMrc(observation);
            } else {
                alert("Error in API response");
            }

        } catch (error) {
            console.error("Error:", error.message);
            alert("An error occurred. Please check the console for details.");
        }
    }

    const liste_dossiers_avis_placeholder = async (id_membre) => {
        try {

            const response = await axios.get(URL_AXIOS + `liste_avis_sollicitePlaceHolder/${id_membre}`);
            if (response.data.status === 200) {

                const dataDossier = response.data.data_avis_solliciteByFolder;
                // const newTitles = {};
                // for (const [dossier_id, avisList] of Object.entries(dataDossier)) {
                //     newTitles[dossier_id] = avisList.map(avis =>(avis.nom_recep+' '+avis.prenom_recep)).join(', '); // Replace 'someField' with the actual field name you want to use
                // }
                setTitles(dataDossier);


            } else {
                alert("Error in API response");
            }

        } catch (error) {
            console.error("Error:", error.message);
            alert("An error occurred. Please check the console for details.");
        }
    }

    const handleClickToDetailComponent = (id) => {
        setStatutView("DC_TO_DD");
        liste_dossiers_interprete(id);
        navigate(`/detail_dossier_patient/${id}`);
    };

    const ActionBody = (data) => {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                <Checkbox inputId={`dossier_${data.id}`} name={data.id} value={data.id} onChange={(e) => onIngredientsChange(e, data.statut,data.exp)} checked={ingredients.includes(data.id)} />
                <i className='fa fa-hand-o-up'
                    onClick={() => navigate(`/member_detail_folder/${data.id}`)}
                    style={{ color: 'red', cursor: 'pointer', marginLeft: 10 }}>
                </i>
                <BiEdit style={{ color: 'green', cursor: 'pointer', fontSize: 15, marginLeft: 10 }} onClick={() => handleClickToDetailComponent(data.id)} />
            </div>
        )
    }
    const ActionBodySatus = (data) => {
        const avisList = titles[data.id] || [];
        const title = avisList.map(avis => `${avis.nom_recep} ${avis.prenom_recep}`).join(', ');
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
                {data.etat == 1 &&
                    <img
                        src="../../../assets/img/urgent.png"
                        width="50"
                        style={{ marginTop: 5 }}
                    />}

                {data.exp == 'O' &&
                    <img
                        src="../../../img/diamond_26bleupx.png"
                        style={{ marginTop: 5, height: "20px", marginLeft: 10 }}
                        title={title || 'Loading...'}
                    />}
            </div>
        )
    }
    const ActionBodyExamen = (data) => {
        return (
            <div style={{ display: 'flex', marginTop: 10 }}>

                {data.nom_fichier !== null && <h6><Link style={{ color: 'green' }}
                    to={'/resultat'}
                    onClick={(e) => handleDownload(e, URL_FOLDER_API + data.chemin_fichier)}
                >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </h6>}{data.nom_fichier_2 !== null && <h6>&nbsp;<Link style={{ color: 'green' }}
                    to={'/resultat'}
                    onClick={(e) => handleDownload(e, URL_FOLDER_API + data.chemin_fichier_2)}
                    target="_blank"
                    rel="noopener noreferrer"
                >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></h6>}
                {data.bulletin_examen !== null && <h6><Link to='/resultat' style={{ position: 'absolute', color: 'green' }} onClick={(e) => handleOpen(e, URL_FOLDER_API + data.chemin_bulletin)}>Bulletin</Link></h6>}

            </div>
        )
    }

    useEffect(() => {
        // liste_dossiers_by_site(14,)
        liste_dossiers_by_siteEnteteAccordion(membre_id);
        setDisableOption(true)
        setDisableAvis(true);
        liste_dossiers_avis_placeholder(membre_id);

        if (usernameconnected === null || usernameconnected === "" ) {
            navigate('/')
        }


    }, []);


    return (
        <div>
            <Header />
            <AsideBar username={usernameconnected} />
            {statusProgress && <ProgressAction />}
          {LoadingState ?  <section id="main-content">
                <section class="wrapper">
                    <div className='row' style={{ padding: 10 }}>
                        <DataTable value={dossiersEn} emptyMessage="Aucun examen disponible!" rowClassName={(data) => data.statut == "0" ? "newFolder" : ""} paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem', backgroundColor: "green" }} className='custom-datatable'>
                            <Column body={ActionBodySatus} bodyStyle={{ textAlign: 'center' }} headerStyle={{ marginLeft: "100px" }} style={{ width: '6%', height: '40px' }}></Column>
                            <Column header={() => { return <div style={{ marginLeft: 20 }}>CENTRE</div> }} headerStyle={{ marginLeft: "100px" }} field='societe' style={{ width: '20%', height: '40px' }}></Column>
                            <Column field='nom_pat' header="PATIENT(S)" style={{ width: '15%', height: '40px' }}></Column>
                            <Column header="EXAMEN(S)" body={ActionBodyExamen} style={{ width: '20%', height: '40px' }}></Column>
                            <Column header="CATEGORIES(S)" field='categorie_fichier' style={{ width: '22%', height: '40px' }}></Column>
                            <Column field='created_at' header="DATE" style={{ width: '12%', height: '40px' }}></Column>
                            <Column header="ACTIONS" body={ActionBody} style={{ width: '20%', height: '40px' }}></Column>
                        </DataTable>
                    </div>

                    {/* <div className='container-fluid mt'>

                        {dossiersEn.length > 0 && <div class="row ">

                            <table class="table table-bordered table-striped table-condensed">
                                <thead>
                                    <tr style={{ backgroundColor: 'white' }}>
                                        <th style={{ color: 'red' }}><center></center></th>
                                        <th style={{ color: 'red' }}>Sites</th>
                                        <th style={{ color: 'red' }}>Patient(s)</th>
                                        <th style={{ color: 'red', width: '20%' }}>Examen(s)</th>
                                        <th style={{ color: 'red' }}>Catégorie(s)</th>
                                        <th style={{ color: 'red' }}>Date</th>
                                        <th colSpan={3} style={{ color: 'red' }}></th>


                                    </tr>
                                </thead>
                                <tbody>
                                    {records
                                        .map((dossierRecu, index) => (
                                            <tr key={dossierRecu.id}>
                                                <td><center>
                                                    {dossierRecu.etat == 1 &&
                                                        <img
                                                            src="../../../assets/img/urgent.png"
                                                            width="50"
                                                            style={{ marginTop: 5 }}
                                                        />}
                                                </center></td>
                                                <td>{dossierRecu.societe}</td>
                                                <td>{dossierRecu.nom_pat}</td>
                                                <td>
                                                    <div style={{ display: 'flex' }}>

                                                        {dossierRecu.nom_fichier !== null && <h6><Link style={{ color: 'green' }}
                                                            to={'/resultat'}
                                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier)}
                                                        >Examen-s1&nbsp;<i className='fa fa-cloud-download'></i>&nbsp;|</Link> </h6>}{dossierRecu.nom_fichier_2 !== null && <h6>&nbsp;<Link style={{ color: 'green' }}
                                                            to={'/resultat'}
                                                            onClick={(e) => handleDownload(e, URL_FOLDER_API + dossierRecu.chemin_fichier_2)}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >Examen-s2<i className='fa fa-cloud-download'></i>&nbsp;|</Link></h6>}
                                                        {dossierRecu.bulletin_examen !== null && <h6><Link to='/resultat' style={{ position: 'absolute', color: 'green' }} onClick={(e) => handleOpen(e, URL_FOLDER_API + dossierRecu.chemin_bulletin)}>Bulletin</Link></h6>}
                                                    </div>
                                                </td>
                                                <td>{dossierRecu.categorie_fichier}</td>
                                                <td>{dossierRecu.created_at}</td>
                                                <td><center>
                                                    <Checkbox inputId={`dossier_${dossierRecu.id}`} name={dossierRecu.id} value={dossierRecu.id} onChange={(e) => onIngredientsChange(e, dossierRecu.statut)} checked={ingredients.includes(dossierRecu.id)} />
                                                </center></td>
                                                <td>
                                                    <i className='fa fa-hand-o-up'
                                                        onClick={() => navigate(`/member_detail_folder/${dossierRecu.id}`)}
                                                        style={{ color: 'red', cursor: 'pointer' }}>
                                                    </i>

                                                </td>
                                                <td>
                                                    <BiEdit style={{ color: 'green', cursor: 'pointer', fontSize: 15 }} onClick={() => handleClickToDetailComponent(dossierRecu.id)} />
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>

                            </table>

                            <nav>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={prevPage}
                                        >Prev</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href='#' className='page-link'
                                                    onClick={() => changeCPage(n)}
                                                >{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href='#' className='page-link'
                                            onClick={nextPage}
                                        >Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>}
                    </div>
                    {dossiersEn.length <= 0 && <div style={{ display: 'flex' }}><img src='../../img/folder_empty.png' /> <h3 style={{ color: 'red', marginTop: '110px' }}>Aucun dossier trouvé</h3></div>} */}


                </section>
            </section> :<Loading />}
            <Footer />
        </div>
    );
}

export default CardResultat;